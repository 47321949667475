import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import Text from '../components/Text';
import { FiCheck } from 'react-icons/fi';
import image from '../img/tagesablauf.gif';
import ReadMoreLink from '../components/ReadMoreLink';
import routes from '../routes';
import {
  SectionGroup,
  SectionGroupDivider,
  SectionGroupItem,
  SectionTitle,
} from '../components/Section';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import BackButton from '../components/BackButton';

export const ConceptPageTemplate: React.FC<any> = ({
  title,
  text,
  text2,
  principles,
  interiorDesign,
  familiarisation,
  toiletTraining,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' });

  const Schedule = () => (
    <div
      style={{
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Link to={routes.schedule}>
        <img src={image} style={{ height: '340px' }} />
      </Link>
      <ReadMoreLink style={{ marginTop: '0' }} to={routes.schedule}>
        weiterlesen
      </ReadMoreLink>
    </div>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', marginBottom: '50px' }}>
        <ContainerInner>
          <PageTitle>{title}</PageTitle>
          {isTabletOrMobile && (
            <div style={{ marginBottom: '50px' }}>
              <Schedule />
            </div>
          )}
          <Text>{text}</Text>
          <PrinciplesList>
            {principles.map((principle) => (
              <PrinciplesListItem>
                <FiCheck
                  style={{
                    marginRight: 12,
                    color: 'hsl(100, 90%, 40%)',
                    fontSize: 25,
                  }}
                />
                <span style={{ fontWeight: 600, fontSize: 17 }}>
                  {principle.text}
                </span>
              </PrinciplesListItem>
            ))}
          </PrinciplesList>
        </ContainerInner>
        {!isTabletOrMobile && <Schedule />}
      </div>
      <SectionGroup>
        <SectionGroupItem>
          <SectionTitle>{interiorDesign.title}</SectionTitle>
          <Text>{interiorDesign.teaser}</Text>
          <ReadMoreLink to={routes.roomDesign}>weiterlesen</ReadMoreLink>
        </SectionGroupItem>
        <SectionGroupDivider />
        <SectionGroupItem>
          <SectionTitle>{familiarisation.title}</SectionTitle>
          <Text>{familiarisation.teaser}</Text>
          <ReadMoreLink to={routes.familarisation}>weiterlesen</ReadMoreLink>
        </SectionGroupItem>
        <SectionGroupDivider />
        <SectionGroupItem>
          <SectionTitle>{toiletTraining.title}</SectionTitle>
          <Text>{toiletTraining.teaser}</Text>
          <ReadMoreLink to={routes.toiletTraining}>weiterlesen</ReadMoreLink>
        </SectionGroupItem>
      </SectionGroup>
      <BackButton
        to="/"
        text="Zurück zur Startseite"
        style={{ marginTop: '30px' }}
      />
    </div>
  );
};

const ConceptPage: React.FC<{ data: any }> = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ConceptPageTemplate
        title={post.frontmatter.title}
        text={post.frontmatter.text}
        principles={post.frontmatter.principles}
        interiorDesign={post.frontmatter.interiorDesign}
        familiarisation={post.frontmatter.familiarisation}
        toiletTraining={post.frontmatter.toiletTraining}
      />
    </Layout>
  );
};

export default ConceptPage;

export const query = graphql`
  query ConceptPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        text
        principles {
          text
        }
        interiorDesign {
          title
          teaser
        }
        familiarisation {
          title
          teaser
        }
        toiletTraining {
          title
          teaser
        }
      }
    }
  }
`;

const ContainerInner = styled.div`
  flex: 1;
  display: flex;
  margin-right: 80px;
  flex-direction: column;
  align-items: flex-start;
`;

const PrinciplesList = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

const PrinciplesListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;
`;
